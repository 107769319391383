import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { useEvalState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Profile() {
  const {
    parameters: [gameUsername],
  } = useRoute();
  const { t } = useTranslation();
  const {
    rocket: { profiles },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[gameUsername]);
  if (!profile) return <div>{t("", "Player is not valid, nice try bud")}</div>;
  return (
    <div>
      <div>{t("", "We found an account on Blitz")}</div>
      <div>
        {t("", "Epic Games - Rocket League: {{username}}", {
          username: profile.gameUsername,
        })}
      </div>
      <div>{t("", "Blitz ID: {{id}}", { id: profile.id })}</div>
    </div>
  );
}

export function meta([gameUsername = "Unknown"]) {
  return {
    title: [
      `rocket:meta.profile.title`,
      `{{gameUsername}}'s - Rocket League Performance Overview`,
      { gameUsername },
    ],
    description: [
      `rocket:meta.profile.description`,
      `View {{gameUsername}}'s Rocket League profile and see how they perform.`,
      { gameUsername },
    ],
  };
}
